$font-family: Nunito, sans-serif;
$font-family-code: Monospace, serif;
$font-family-icon: Material Symbols Rounded;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

$font-size-10: .714rem;
$font-size-12: .857rem;
$font-size-13: .929rem;
$font-size-14: 1rem;
$font-size-16: 1.143rem;
$font-size-18: 1.286rem;
$font-size-20: 1.429rem;
$font-size-22: 1.571rem;
$font-size-24: 1.714rem;
$font-size-26: 1.857rem;
$font-size-28: 2rem;
$font-size-34: 2.429rem;
$font-size-36: 2.571rem;
$font-size-48: 3.429rem;
$font-size-70: 5rem;

$box-shadow: 0 0 16px #00000020;
$box-shadow-green: 0 0 16px var(--color-green);
$box-shadow-cyan: 0 0 16px #16D2A550;

$box-shadow-black-m: 0 0 16px 0 #00000016;
$box-shadow-black-l: 0 0 30px 0 #00000010;

$breakpoint-s: 480px;
$breakpoint-m: 768px;
$breakpoint-l: 960px;

$screen-s: "screen and (max-width: calc(#{$breakpoint-s} - 1px))";
$screen-m: "screen and (min-width: #{$breakpoint-s}) and (max-width: calc(#{$breakpoint-m} - 1px))";
$screen-l: "screen and (min-width: #{$breakpoint-m}) and (max-width: calc(#{$breakpoint-l} - 1px))";
$screen-xl: "screen and (min-width: #{$breakpoint-l})";

$animation-duration: .75s;
$animation-duration-fast: .25s;

$z-index-main-header-module: 22; // Based on $z-index-main-header (ui-lib)
$z-index-main-alerts: 60;
