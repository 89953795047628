.answer-buttons {
    &__button {
        & > input {
            display: none;
    
            &:checked + .answer-buttons__button__label {
                color: var(--color-ocean);
                border-color: var(--color-ocean);
                box-shadow: 0 1px var(--color-ocean);
            }
        }
    
        &__label {
            border: 1px solid var(--color-grey);
            border-radius: var(--border-radius-xs);
            background-color: var(--color-grey-light);
            padding: var(--spacing-xs) var(--spacing-sm);
    
            &:hover,
            &:active {
                color: var(--color-ocean);
                border-color: var(--color-ocean);
                box-shadow: inset 1px var(--color-ocean);
            }
        }
    }
}