$step-number-size: 2rem;

.installation-steps {
    gap: 0;

    .installation-step {
        display: flex;
        flex-direction: column;
    
        &__title {
            & > div {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: $step-number-size;
                height: $step-number-size;
                border: 1px solid var(--color-grey);
                background-color: var(--color-grey-light);
                border-radius: var(--border-radius-xs);
                font-size: $font-size-18;
                font-weight: $font-weight-bold;
                margin-right: var(--spacing-lg);
            }

            & > * {
                color: var(--color-ocean);
            }
        }
    
        &__content {
            display: flex;
            flex-direction: column;
            gap: var(--spacing-sm);
            border-left: 1px solid var(--color-grey);
            margin-left: calc($step-number-size / 2);
            padding: var(--spacing-md) 0 var(--spacing-xl) calc($step-number-size / 2 + var(--spacing-lg));

            & > p {
                margin: 0;
                line-height: 1.8;
            }
        }

        &:last-child > .installation-step__content {
            border-image: linear-gradient(to bottom, var(--color-grey) 85%, transparent) 1;
        }
    }
}

.installation-loader {
    justify-content: flex-start;
}
