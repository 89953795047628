.configuration-mode {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: calc(var(--spacing-md) * 2) !important;
  height: 33rem;

  &__content {
    text-align: left;
    color: var(--color-midnight-light);

    &__header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: var(--spacing-md);
    }

    &__title {
      display: inline-block;
      font-size: $font-size-26;
      font-weight: $font-weight-bold;
      color: var(--color-midnight);
      margin-bottom: var(--spacing-sm);
    }

    &__description {
      font-size: $font-size-14;
      font-weight: $font-weight-regular;
      color: var(--color-midnight-light);
      margin-bottom: var(--spacing-md);
    }

    &__steps {
      display: flex;
      flex-direction: column;
      justify-content: left;
      margin-bottom: var(--spacing-md);
      gap: var(--spacing-xs);

      &__item {
        display: inline-flex;
        text-transform: initial;
        font-size: $font-size-14;
        gap: var(--spacing-sm);

        &__icon {
          display: inline-flex;
          color: var(--color-green);
          font-size: $font-size-20;
        }
      }
    }
  }
}