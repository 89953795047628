.main-header__notification-menu {
  position: relative;
  top: 0;
  right: 50%;

  &__overlay {
    display: none;
    position: absolute;
    top: -3rem;
    right: -2rem;
    width: 27rem;
    height: 14rem;
  }

  &__content {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 23rem;
    background-color: var(--color-white);
    border-radius: var(--border-radius-md);
    border: 1px solid var(--color-grey);
    box-shadow: $box-shadow;
    color: var(--color-midnight);
    overflow: hidden;
    overflow-y: auto;
    z-index: $z-index-main-header-module;
    user-select: none;

    @media #{$screen-s} {
      position: fixed;
      top: calc(var(--main-header-height) / 2);
      left: 2rem;
      right: 2rem;
      width: unset;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 1.25rem;
    font-size: $font-size-18;
    font-weight: $font-weight-semi-bold;
    color: var(--color-midnight-light);

    &__actions {
      display: flex;
      align-items: center;

      &__icon {
        cursor: pointer;

        &:hover {
          color: var(--color-green);
        }
      }
    }
  }

  &__items {
    max-height: 30rem;
    padding-bottom: 1rem;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: .357rem;
    }

    &::-webkit-scrollbar-thumb {
      border: 0 solid transparent;
      border-top-left-radius: calc(var(--border-radius-md) / 4);
      border-bottom-left-radius: calc(var(--border-radius-md) / 4);
      -webkit-box-shadow: inset 0 0 0 3px var(--color-midnight);
      -moz-box-shadow: inset 0 0 0 3px var(--color-midnight);
      box-shadow: inset 0 0 0 3px var(--color-midnight);
    }

    &::-webkit-scrollbar-track {
      margin-bottom: var(--border-radius-md);
    }
  }

  &__item {
    margin: .5rem;
    padding: .75rem 1.25rem;
    border-radius: var(--border-radius-md);
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    &--unread {
      font-weight: $font-weight-bold;
    }

    &:hover {
      background-color: var(--color-grey-light);

      .main-header__notification-menu__item__actions {
        display: flex;
      }
    }

    &__content {
      width: 100%;

      &__text {
        font-size: $font-size-14;
        color: var(--color-midnight);
        padding-right: 1rem;

        a {
          text-decoration: none;
        }
      }

      &__info {
        display: inline-flex;
        align-items: center;
        font-size: $font-size-10;
        color: var(--color-midnight-lighter);

        &__chip {
          font-size: inherit;
          color: var(--color-green);
          margin-left: .25rem;
        }
      }

    }

    &__actions {
      display: none;
      flex: 1;
      align-self: flex-start;
      justify-content: flex-end;
      position: absolute;
      right: .5rem;

      &__icon {
        font-size: $font-size-20;

        &:not(:hover) {
          color: var(--color-midnight-light);
        }
      }
    }
  }

  &--active {
    .main-header__notification-menu__overlay,
    .main-header__notification-menu__content {
      display: block;
    }
  }
}
