.onboarding {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--color-midnight);
  background: radial-gradient(circle, var(--color-midnight) 50%, var(--color-midnight-dark) 100%);
  overflow: auto;

  .onboarding-page {
    padding: var(--spacing-xl) var(--spacing-lg);

    .onboarding-form {
      max-width: 50rem;
      margin: auto;
      text-align: center;
      color: var(--color-grey);

      &.onboarding-form--large {
        max-width: 58rem;
      }

      .main-logo {
        height: 3.5rem;
        margin: 0 auto calc(var(--spacing-md) * 2);

        img,
        svg {
          height: 100%;
          width: auto;
        }
      }

      .title {
        margin-bottom: var(--spacing-md);
        font-size: $font-size-26;
        font-weight: $font-weight-bold;
        letter-spacing: 0.4pt;

        & span {
          color: var(--color-green);
        }
      }

      .description {
        display: block;
        margin: 0 var(--spacing-sm) calc(var(--spacing-md) * 2);
        font-size: $font-size-18;
        font-weight: $font-weight-regular;
      }
    }
  }
}
