.main-header__searchbar {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-right: .5rem;
  width: 20vw;

  input[type="text"].main-header__searchbar__input {
    margin: 0;
    padding-left: calc(var(--spacing-md) * 2);
    box-shadow: none;
    border: 1px solid var(--color-grey);

    &.main-header__searchbar__input--opened {
      border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;

      & + ul.main-header__searchbar__results {
        display: block;
      }
    }
  }

  ul.main-header__searchbar__results {
    display: none;
    position: absolute;
    top: 100%;
    width: 100%;
    background-color: var(--color-white);
    border: 1px solid var(--color-grey);
    border-radius: 0 0 var(--border-radius-md) var(--border-radius-md);
    max-height: 20rem;
    overflow: auto;
    z-index: $z-index-main-header-module;

    &:not(:hover) {
      li.main-header__searchbar__results__item--selected {
        background-color: var(--color-grey-light);
      }
    }

    li {
      display: flex;
      align-items: center;
      padding: 0.5rem var(--spacing-md) 0.5rem calc(var(--spacing-md) * 2);
      font-size: $font-size-14;
      background-color: inherit;
      color: var(--color-midnight-light);
      font-weight: $font-weight-regular;
      cursor: pointer;

      &:hover {
        background-color: var(--color-grey-light);
      }

      > span {
        margin-right: var(--spacing-xs);
      }

      strong {
        word-break: break-all;
      }

      i {
        display: flex;
        flex: 1;
        justify-content: flex-end;
      }
    }
  }

  > i {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0.75rem;
    width: 1.75rem;
    height: 1.75rem;
    margin: auto;
    color: var(--color-midnight-light);
  }

  @media #{$screen-s}, #{$screen-m}, #{$screen-l} {
    display: none;
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    box-shadow: $box-shadow;

    &.main-header__searchbar--active {
      display: flex;
    }

    input[type=text].main-header__searchbar__input {
      padding: 1.5rem 1.5rem 1.5rem 3rem;
      border-radius: unset !important;
    }

    ul.main-header__searchbar__results {
      border-left: 0;
      border-right: 0;
      border-radius: unset !important;
      box-shadow: $box-shadow;
    }
  }
}
