.form-layout {
  &__message {
    font-size: $font-size-14;

    &--small {
      font-size: $font-size-12;
    }

    &--danger {
      color: var(--color-danger-main);
    }

    &--warning {
      color: var(--color-warning-main);
    }

    &--success {
      color: var(--color-success-main);
    }

    &--info {
      color: var(--color-info-main);
    }
  }
}