.login-1col {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--color-midnight);
  background: radial-gradient(circle, var(--color-midnight) 50%, var(--color-midnight-dark) 100%);
  overflow: auto;

  &-content {
    max-width: 45rem;
    min-height: 70vh;
    margin: auto;
    padding: var(--spacing-xl) var(--spacing-lg);
    text-align: center;

    .main-logo {
      height: 3.5rem;
      margin: 0 auto 3em;

      img,
      svg {
        height: 100%;
        width: auto;
      }
    }

    &-title {
      margin-bottom: var(--spacing-xl);
      font-size: $font-size-20;
      font-weight: $font-weight-bold;
      letter-spacing: 0.4pt;
      color: var(--color-grey);
    }

    &-section {
      margin-bottom: calc(var(--spacing-md) * 2);

      &-text {
        margin-bottom: calc(var(--spacing-md)*2);
        font-size: $font-size-18;
        font-weight: $font-weight-regular;
        color: var(--color-grey);
        text-align: center;
      }
    }

    .btn-google {
      width: 18rem;
      height: 3rem;
      margin: auto;
      background-color: #4285F4;
      box-shadow: $box-shadow;
      border-radius: var(--border-radius-xs);
      cursor: pointer;
      display: flex;
      align-items: center;
      color: var(--color-white);
      font-weight: $font-weight-bold;

      &:hover {
        box-shadow: 2px 2px 6px var(--color-grey-dark);
      }

      &:before {
        content: '';
        margin: 1px;
        padding: 20px;
        background: url("../img/logo-google.svg") center/40% no-repeat var(--color-white);
      }

      span {
        margin: auto;
      }
    }
  }
}

.login-left {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 40%;
  overflow: auto;
  display: block;

  &-content {
    max-width: 35rem;
    margin: 15vh auto 0;
    padding: 0 var(--spacing-lg);

    .main-logo {
      height: 7rem;
      text-align: center;

      img, svg {
        height: 100%;
        width: auto;
      }
    }
  }
}

.login-left,
.login-bottom {
  background-color: var(--color-midnight);
  background-image: url("../img/waves-opacity.png");
  background-repeat: no-repeat;
  background-position: bottom;

  .login-items {
    &:not(:first-child) {
      margin-top: 7vh;
    }

    ul {
      display: -moz-box;
      display: -ms-flexbox;
      display: grid;
      flex-wrap: wrap;
      justify-content: center;
      list-style: none;

      li {
        position: relative;
        padding: var(--spacing-sm);
        padding-left: var(--spacing-lg);
        font-size: $font-size-18;
        color: var(--color-grey);
        line-height: 1.5;

        &::before {
          position: absolute;
          left: 0;
          content: '\e5ca';
          font-family: $font-family-icon;
          color: var(--color-green);
          margin-right: var(--spacing-xs);
        }
      }
    }
  }
}

.login-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;
  margin-left: 40%;
  min-height: 100vh;

  &__content {
    text-align: center;

    &__title {
      max-width: 45rem;
      margin: 15vh auto var(--spacing-xl);
      padding: var(--spacing-sm);
      font-size: $font-size-20;
      font-weight: $font-weight-bold;
      letter-spacing: 0.4pt;
      color: var(--color-midnight-light);
    }

    &__section {
      margin-bottom: calc(var(--spacing-md) * 2);

      &__text {
        margin-bottom: var(--spacing-sm);
        font-size: $font-size-18;
        font-weight: $font-weight-regular;
        color: var(--color-midnight-light);
        text-align: center;
      }
    }

    .logos {
      display: flex;
      margin-top: 8rem;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;

      .logo {
        width: 5rem;
        margin: var(--spacing-sm) calc(var(--spacing-xs) / 2);

        img {
          height: 2rem;
          margin-bottom: var(--spacing-sm);
        }

        span {
          display: block;
          font-size: $font-size-12;
          color: var(--color-midnight);
        }
      }
    }
  }

  &__footer {
    border-top: 1px solid var(--color-grey);
  }
}

.login-bottom {
  display: none;
  padding: calc(var(--spacing-md) * 2);

  .footer__link {
    color: var(--color-midnight-lighter);
  }

  .login-items {
    ul {
      text-align: center;
      li {
        font-size: $font-size-16;
        padding: var(--spacing-xs);
      }
    }
  }
}

/* RESPONSIVE */
@media #{$screen-s}, #{$screen-m} {
  .login-left {
    position: relative;
    width: 100%;
    padding: calc(var(--spacing-md) * 2);

    &-content {
      position: relative;
      top: unset;
      left: unset;
      right: unset;
      margin: auto;
      padding: 0;
    }

    .login-items {
      display: none;
    }
  }

  .login-right {
    width: 100%;
    margin-left: 0;
    min-height: unset;

    &__content {
      width: 100%;

      &__title {
        margin: calc(var(--spacing-md) * 2) auto;
      }

      .logos {
        margin-top: var(--spacing-xl);
      }
    }

    &__footer {
      display: none;
    }
  }

  .login-bottom {
    display: block;
    padding-bottom: 0;

    .login-items {
      ul {
        li {
          position: unset;
  
          &::before {
            position: unset;
          }
        }
      }
    }
  }
}

@media #{$screen-s} {
  .login-left {
    &-content {
      .main-logo {
        height: 5rem;
      }
    }
  }

  .login-right {
    &__content {
      width: 100%;
    }
  }

  .login-left,
  .login-bottom {
    padding: var(--spacing-md);
  }
}
