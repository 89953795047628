.home {
  & &__support {
    display: flex;
    align-items: center;
    flex-direction: column;

    .support__picture {
      display: flex;
      width: 100%;
      max-width: 15rem;

      img {
        width: 100%;
        height: 100%;
      }
    }

    button {
      width: 100%;
      max-width: 14rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
