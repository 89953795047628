.home {
  & &__news {
    display: flex;
    flex-direction: column;

    .news__loader {
      margin: 8rem 0;
    }

    .news__item {
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }

      .news__item__date {
        margin-bottom: .5rem;
        font-size: $font-size-12;
        color: var(--color-green);
      }

      .news__item__title {
        font-weight: $font-weight-bold;

        & > span {
          text-align: left;
        }
      }
    }
  }
}
