.modal-create-config {
  display: flex;

  .modal-create-config__mode {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    width: 50%;
    height: 26rem;

    &:first-child {
      border-right: 1px solid var(--color-grey);
      padding-right: var(--spacing-lg);
    }

    &:last-child {
      padding-left: var(--spacing-lg);
    }

    &__content {
      text-align: left;
      color: var(--color-midnight-light);

      &__header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: var(--spacing-sm);
      }

      &__title {
        display: inline-block;
        font-size: $font-size-20;
        font-weight: $font-weight-bold;
        color: var(--color-midnight);
        margin-bottom: var(--spacing-sm);
      }

      &__description {
        font-size: $font-size-14;
        font-weight: $font-weight-regular;
        color: var(--color-midnight-light);
        margin-bottom: var(--spacing-md);

        @media #{$screen-s}, #{$screen-m} {
          display: none;
        }
      }

      &__steps {
        display: flex;
        flex-direction: column;
        justify-content: left;
        margin-bottom: var(--spacing-md);
        gap: var(--spacing-xs);

        &__item {
          display: inline-flex;
          text-transform: initial;
          font-size: $font-size-14;
          gap: var(--spacing-sm);

          &__icon {
            display: inline-flex;
            color: var(--color-green);
            font-size: $font-size-20;
          }
        }
      }

      &__alert {
        @media #{$screen-s} {
          display: none;
        }
      }
    }
  }
}
