.home {
  & &__icon-priority {
    color: var(--color-white);
    background: var(--color-orange);
    border-radius: var(--border-radius-xs);
    padding: calc(var(--spacing-xs) / 2);
    font-size: $font-size-18;
  }

  & &__inprogress {
    display: flex;
    flex-direction: column;

    .inprogress--loader {
      margin-bottom: 4rem;
    }

    .inprogress__item {
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid var(--color-grey);
      }

      .inprogress__item__infos {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;
        font-size: $font-size-16;
        font-weight: $font-weight-semi-bold;
        color: var(--color-midnight);

        .inprogress__item__infos__icon-button {
          color: var(--color-midnight-light);
        }
      }

      .inprogress__item__progress {
        display: flex;
        align-items: center;

        .inprogress__item__progress__bars {
          position: relative;
          width: 60%;
          height: .5rem;
          margin-right: 1rem;

          > span {
            position: absolute;
            top: 0;
            left: 0;
            max-width: 100%;
            border-radius: 3px;
            border: .25rem solid var(--color-green);
            background-color: var(--color-green);
          }

          .inprogress__item__progress__bars__back {
            width: 100%;
            opacity: 0.25;
          }

          .inprogress__item__progress__bars__track {
            width: 33%;
            transition: width 0.2s ease-in-out;
          }
        }

        .inprogress__item__progress__state {
          font-size: $font-size-14;
          color: var(--color-midnight-light);
        }
      }
    }
  }
}
