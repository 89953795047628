.wrapper {
  &.theme-sirdata-midnight {
    // default CSS
  }

  &.theme-sirdata-white {
    background-color: var(--color-grey-light);

    .table {
      thead {
        th {
          background-color: var(--color-midnight) !important;
          color: var(--color-grey) !important;
        }
      }
    }
  }
}
