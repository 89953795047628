.main-header__account-menu {
  position: relative;
  top: 0;
  right: 50%;

  &__overlay {
    display: none;
    position: absolute;
    top: -3rem;
    right: -2rem;
    width: 24rem;
    height: 15rem;
  }

  &__content {
    display: none;
    position: absolute;
    padding: 2rem;
    top: 0;
    right: 0;
    width: 20rem;
    max-width: 90vw;
    background-color: var(--color-white);
    border-radius: var(--border-radius-md);
    border: 1px solid var(--color-grey);
    box-shadow: $box-shadow;
    color: var(--color-midnight);
    overflow: hidden;
    overflow-y: auto;
    z-index: $z-index-main-header-module;
    user-select: initial;
    text-align: center;

    &__row {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    &__avatar {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;

      > span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        background-color: var(--color-midnight);
        color: var(--color-grey);
        font-size: $font-size-36;
        font-weight: $font-weight-bold;
        text-transform: uppercase;

        i {
          font-size: inherit;
        }
      }
    }

    &__name {
      line-height: 1.5;
      font-size: $font-size-18;
      font-weight: $font-weight-bold;
      color: var(--color-midnight);
      word-break: break-all;
    }

    &__label-big {
      line-height: 1.5;
      font-size: $font-size-14;
      font-weight: $font-weight-bold;
      color: var(--color-midnight);
      word-break: break-all;
    }

    &__label {
      font-size: $font-size-14;
      font-weight: $font-weight-regular;
      color: var(--color-midnight-light);
      word-break: break-all;
    }

    &__separator {
      margin-top: 1rem;
      margin-bottom: 1rem;
      width: 100%;
      height: 1px;
      background: var(--color-grey);
    }
  }

  &--active {
    .main-header__account-menu__overlay,
    .main-header__account-menu__content {
      display: block;
    }
  }
}
