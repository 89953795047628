.home {
  & &__notification-carousel {
    height: auto;
    min-height: unset;

    &.notification-carousel--has-no-pagination {
      padding-bottom: 0;
    }

    .notification-carousel__slide {
      position: relative;
      width: 100%;
      padding-bottom: 50%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      &--clickable {
        transition: all .25s ease;

        &:hover {
          opacity: .75;
        }
      }

      &__content {
        width: 100%;
        position: absolute;
        bottom: 0;
        padding: 2rem;
        background-color: var(--color-grey-light);
        opacity: .9;

        & span {
          display: block;

          & + * {
            margin-top: .5rem;
          }
        }

        &__title {
          font-size: $font-size-18;
          font-weight: $font-weight-bold;
          color: var(--color-green);
        }

        &__subtitle {
          font-size: $font-size-16;
          font-weight: $font-weight-bold;
          color: var(--color-midnight-light);
        }

        &__text {
          color: var(--color-midnight-light);
        }
      }
    }
  }
}
