$main-alerts-margin: var(--spacing-md);

.main-alerts {
  position: fixed;
  top: 0;
  height: 0;
  z-index: $z-index-main-alerts;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-sm);
  transition: top 1.5s ease-in-out;

  &--expired {
    top: -100vh;
  }

  &__item {
    position: relative;
    left: calc(50vw - 50%);
    margin: 0 $main-alerts-margin;
    box-shadow: $box-shadow-black-l;

    &:nth-child(1) {
      margin-top: $main-alerts-margin;
    }
  }
}
