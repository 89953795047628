.analytics {
  &__hits {
    margin-top: var(--spacing-lg);

    &__metric {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-bottom: var(--spacing-lg);
  
      &__header {
        font-size: $font-size-16;
        color: var(--color-grey-dark);
      }
  
      &__value {
        color: var(--color-cyan);
        font-size: $font-size-40;
        font-weight: $font-weight-bold;
        line-height: 1;
      }
    }
  }
}
