.cards {
  margin: 2rem 0 3rem;

  .cards-title {
    font-size: $font-size-18;
    font-weight: $font-weight-semi-bold;
    color: var(--color-midnight-light);
    margin-bottom: 1.5rem;
  }

  .cards-items {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(21rem, 1fr));

    &--small,
    &.cards-items-small {
      grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));

      @media #{$screen-s} {
        grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
      }
    }

    &--list,
    &.cards-items-list {
      grid-template-columns: auto;

      @media #{$screen-s} {
        grid-template-columns: repeat(auto-fill, minmax(21rem, 1fr));
      }
    }

    &--thin-gap {
      grid-gap: 1rem;
    }
  }
}

.card-simple {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 14rem;
  text-decoration: none;

  .card-menu {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .card-icon {
    font-size: $font-size-70 !important;
    color: var(--color-midnight-light);
  }

  .card-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5rem;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .card-name {
    margin: 1.5rem auto 0;
    max-height: 3rem;
    overflow: hidden;
    line-height: 1.5;
    font-size: $font-size-16;
    font-weight: $font-weight-semi-bold;
    color: var(--color-midnight);
    text-align: center;
    word-break: break-word;
  }

  input.card-input {
    margin: 1.5rem auto 0;
    padding: 0.5rem 1rem;
  }

  &:hover,
  &.active {
    box-shadow: $box-shadow-green;

    .card-icon,
    .card-name {
      color: var(--color-green);
    }
  }

  @media #{$screen-s} {
    padding: 1.5rem 1rem!important;
    height: auto;

    .card-icon {
      font-size: $font-size-36;
    }

    .card-name {
      margin: 1rem auto 0;
      font-size: $font-size-14;
    }
  }
}
