.main-header__origin-menu {
  position: relative;
  top: 0;
  right: 50%;

  &__overlay {
    display: none;
    position: absolute;
    top: -3rem;
    right: -2rem;
    width: 17rem;
    height: 25rem;
  }

  &__content {
    display: none;
    position: absolute;
    padding: .5rem;
    top: 0;
    right: 0;
    background-color: var(--color-white);
    border-radius: var(--border-radius-md);
    border: 1px solid var(--color-grey);
    box-shadow: $box-shadow;
    color: var(--color-midnight);
    overflow: hidden;
    overflow-y: auto;
    z-index: $z-index-main-header-module;
    user-select: none;

    &__items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      width: 12rem;
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 5rem;
      margin: .5rem;
      padding: .5rem;
      border-radius: var(--border-radius-md);
      border: 1px solid transparent;
      text-decoration: none;
      text-align: center;
      cursor: pointer;

      &:hover {
        background: var(--color-grey-light);
        border: 1px solid var(--color-grey);
      }

      svg {
        width: 2.5rem;
        height: 2.5rem;

        @media #{$screen-s} {
          width: 2rem;
          height: 2rem;
        }
      }

      &__name {
        margin-top: .5rem;
        font-size: .857rem;
        font-weight: $font-weight-semi-bold;
      }
    }
  }

  &--active {
    .main-header__origin-menu__overlay,
    .main-header__origin-menu__content {
      display: block;
    }
  }
}
